*, ::before, ::after {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
}
.root {
    margin: 0px;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    background-color: #0a192f;
    color: #8892b0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    line-height: 1.3;
}

main {
    min-height: 100vh;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 150px;
}
@media screen and (max-width: 780px) {
    main {
        padding: 0 50px;
    }
  }
@media screen and (max-width: 480px) {
    main {
        padding: 0 25px;
    }
  }